// extracted by mini-css-extract-plugin
export const container = "Footer-module--container--27XMY";
export const content = "Footer-module--content--1BA-K";
export const box = "Footer-module--box--3VF5I";
export const title = "Footer-module--title--1c-F9";
export const txt = "Footer-module--txt--hj3kl";
export const beian = "Footer-module--beian--bqA6g";
export const logo = "Footer-module--logo--2li0N";
export const qrcode = "Footer-module--qrcode--1OLmw";
export const devider = "Footer-module--devider--3HSPL";
export const bottom = "Footer-module--bottom--1OeZl";
export const item = "Footer-module--item--1iL7K";
export const inputBox = "Footer-module--inputBox--3mPHp";
export const input = "Footer-module--input--CBukN";
export const inputBtn = "Footer-module--inputBtn--zFQuf";
export const icon = "Footer-module--icon--38MB6";