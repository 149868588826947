import React from "react"
import PropTypes from "prop-types"
import cx from 'classnames'

const Icon = ({ type, className }) => <i className={cx("iconfont", type, className)} />

Icon.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
}

Icon.defaultProps = {
  contentClassName: '',
  className: ''
}

export default Icon