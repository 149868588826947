import React from "react"
import { Link } from 'gatsby'
import Icon from './Icon'
import * as styles from './Footer.module.less'
import { logo_white, tel, dingyuehao, processPath, aboutPath } from '../utils/constant'

const Footer = () => {
  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <div className={styles.box}>
          <div>
            <p className={styles.title}>获得最新产品和活动资讯</p>
            <div className={styles.inputBox}>
              <input className={styles.input} type="text" placeholder="请输入您的邮箱" />
              <div className={styles.inputBtn}>订阅</div>
            </div>
            <Link to="/">
              <img className={styles.logo} src={logo_white} alt="宝猫logo"/>
            </Link>
          </div>

          <div>
            <p className={styles.title}>联系我们</p>
            <p className={styles.txt} style={{ marginTop: 20 }}>电话：{tel}</p>
            <p className={styles.txt} style={{ marginTop: 8 }}>邮箱：service@rapnetbuy.com</p>
            <p className={styles.txt} style={{ marginTop: 24 }}>扫二维码随时获取RAPNETBUY（宝猫科技）服务帮助 <Icon type="iconarrows2" className={styles.icon} /></p>
          </div>
          <img className={styles.qrcode} src={dingyuehao} alt="宝猫服务号"/>
        </div>
        <div className={styles.devider} />
        <div className={styles.bottom}>
          <div>
            <Link to="/" className={styles.item}>全球钻石</Link>
            <Link to={processPath} className={styles.item}>RAPNETBUY（宝猫科技）贸易流程</Link>
            <Link to={aboutPath} className={styles.item}>关于我们</Link>
          </div>
          <span className={styles.beian}>
            Copyright © 2021 rapnetbuy.com 版权所有 |
            &nbsp;<a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备18013404号-11</a>
          </span>
        </div>
      </div>
    </section>
  )
}

export default Footer