import React from "react"
import { Link } from 'gatsby'
import { useLocation } from "@reach/router"
import cx from 'classnames'
import Icon from '../components/Icon'
import { getLoginPath } from '../utils/util'
import { logo, logo_white, tel, processPath, aboutPath } from '../utils/constant'
import * as styles from './Header.module.less'

const Header = ({ plain }) => {
  const { pathname } = useLocation()
  return (
    <section className={cx(styles.container, { [styles.plain]: plain })}>
      <div className={styles.left}>
        <Link to="/" style={{ lineHeight: 0 }}>
          <img className={styles.logo} src={plain ? logo : logo_white} alt="宝猫logo"/>
        </Link>
        <div className={styles.navs}>
          <Link className={cx(styles.navItem, { [styles.active]: pathname === '/' })} to="/">全球钻石</Link>
          <Link className={cx(styles.navItem, { [styles.active]: pathname === processPath })} to={processPath}>独特优势</Link>
          <Link className={cx(styles.navItem, { [styles.active]: pathname === aboutPath })} to={aboutPath}>关于宝猫</Link>
        </div>

      </div>
      <div className={styles.right}>
        <div style={{ fontWeight: 500 }}>
          <Icon type="iconphone" className={styles.phoneIcon} />
          <span>联系电话：{tel}</span>
        </div>
        <a className={styles.loginBtn} href={getLoginPath()}>注册/登录</a>
      </div>
    </section>
  )
}

export default Header