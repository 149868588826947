import React from "react"
import Layout from '../components/Layout'
import * as styles from './process.module.less'

const processTxts = [
  { key: 0, title: '如何注册成为RAPNETBUY（宝猫科技）买家？', desc: '在RAPNETBUY（宝猫科技）注册，提交企业营业执照、法人证件等认证材料，RAPNETBUY（宝猫科技）审核通过后即可成为平台买家用户，在RAPNETBUY（宝猫科技）选购全球钻石。' },
  { key: 1, title: 'RAPNETBUY（宝猫科技）平台上的钻石来自于哪里？', desc: 'RAPNETBUY（宝猫科技）为您整合全球钻石卖家，为您提供一站式的采购体验。入驻RAPNETBUY（宝猫科技）的卖家均为与RapNet平台长期合作的优质供应商。' },
  { key: 2, title: '如何支付订单？', desc: '在您下单后，卖家将在2小时内为您确认商品库存并留钻，全部商品库存确认完成后即可开始支付。您可以在订单中心下载付款申请单，在24小时内向指定账户完成对公转账，并及时上传支付凭证，待平台确认到账后，订单支付完成。' },
  { key: 3, title: '什么是NGTC海外预检？', desc: '下单时选择NGTC检测项目，即可在货源地完成挑石预检，级别不符的钻石全额退款，为您减免钻石进关后出证掉级的风险。' },
  { key: 4, title: '我下单后多久能收到商品？', desc: '根据您下单商品的所在地，中国境内货源通常3个工作日内完成清关发货。境外货源统一使用钻石专用物流，保证运输安全和时效，通常在7-14工作日完成清关发货。' },
  { key: 5, title: '如何查看我的商品发货进度？', desc: '在“交易中心”页面可实时查看商品运输情况，包括卖家发货、海外RapNet收货、NGTC实验室预检、封包起运等信息，实时追踪物流动态。' },
  { key: 6, title: '我的钻石在哪里报关？', desc: '上海钻石交易所是中国大陆唯一的钻石进出口交易平台。宝猫科技旗下爱丽仕得（上海）贸易有限公司，上海钻交所会员机构，为您采购的钻石进口报关，保证交易流程合法合规。' },
  { key: 7, title: '如何签订采购合同？如何开票？', desc: '待您采购的钻石完成海外预检后（付款后3-4个工作日），即可在订单中心下载采购合同。下载合同，打印盖章后扫描上传至平台，同时将原件邮寄到指定地址。如订单发生退款，退款金额将在质检完成后3个工作日内退回支付账户。平台将根据最终合同金额为您开具增值税专用发票，发票将在收到纸质合同的2个工作日内寄出。' },
]

const Process = () => (
  <Layout plainHeader seoProps={{ title: "交易流程" }}>
    <section className={styles.main}>
      <div className={styles.banner} />
      <div className={styles.container}>
        <div className={styles.content}>
          <h2 className={styles.title}>一键直采全球钻石更安全快捷</h2>
          <div className={styles.steps} />
          <div className={styles.descBox}>
            <ul className={styles.infoList}>
              <li className={styles.infoItem}>- 全程担保交易, 降低跨境直采贸易风险</li>
              <li className={styles.infoItem}>- RAPNET 海外收货, NGTC海外预挑石 确保钻石等级</li>
              <li className={styles.infoItem}>- 海外物流全程跟踪, 全程保险, 上海钻交所进关,全流程透明安全</li>
            </ul>
          </div>
          <div className={styles.qaBox}>
            <h1 className={styles.qaTitle}>常见问题</h1>
            <section>
              {processTxts.map(txt => (
                <div key={txt.key} className={styles.qaItem}>
                  <p className={styles.question}>{txt.title}</p>
                  <p className={styles.answer}>{txt.desc}</p>
                </div>
              ))}

            </section>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Process
