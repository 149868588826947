// extracted by mini-css-extract-plugin
export const main = "process-module--main--1LqMX";
export const banner = "process-module--banner--2oW5e";
export const container = "process-module--container--3CVHJ";
export const title = "process-module--title--2dM-j";
export const content = "process-module--content--1g_9a";
export const descBox = "process-module--descBox--MaIDV";
export const infoList = "process-module--infoList--2MB0g";
export const infoItem = "process-module--infoItem--2xI44";
export const steps = "process-module--steps--1Qs78";
export const qaBox = "process-module--qaBox--3W31H";
export const qaTitle = "process-module--qaTitle--1s6ao";
export const qaItem = "process-module--qaItem--2waEd";
export const question = "process-module--question--12b9I";
export const answer = "process-module--answer--2QQEw";