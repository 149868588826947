import React from "react"
import PropTypes from "prop-types"
import cx from 'classnames'
import SEO from './SEO'
import Header from './Header'
import Footer from './Footer'
import * as styles from './Layout.module.less'

const Layout = ({ children, contentClassName, plainHeader, seoProps }) => {
  return (
    <>
      <SEO {...seoProps} />
      <Header plain={plainHeader} />
      <main className={cx(styles.main, contentClassName)}>
        {children}
      </main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  contentClassName: PropTypes.string,
  plainHeader: PropTypes.bool,
  seoProps: PropTypes.shape()
}

Layout.defaultProps = {
  contentClassName: '',
  plainHeader: false,
  seoProps: {}
}

export default Layout