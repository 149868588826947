// export const logo = 'https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/home/logo-r.png'
export const logo = 'https://wanbaorapdia.oss-cn-shenzhen.aliyuncs.com/fileserver/image/rapnetbuy/logo-r-new-2.png'
// export const logo_white = 'https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/home/logo.png'
export const logo_white = 'https://wanbaorapdia.oss-cn-shenzhen.aliyuncs.com/fileserver/image/rapnetbuy/logo-web-white-2.png'
// export const qrcode = 'https://wanbaorapdia.oss-cn-shenzhen.aliyuncs.com/fileserver/image/rapnetbuy/qrcode.png'
export const qrcode = 'https://wanbaorapdia.oss-cn-shenzhen.aliyuncs.com/fileserver/image/rapnetbuy/customer-service-code-3.png'
export const diamond_1 = 'https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/diamond_1.jpeg'
export const diamond_2 = 'https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/diamond_2.jpeg'
export const rapnet_1 = 'https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/home/Rapnet.png'
export const rapnet_2 = 'https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/rapnet_2.png'
export const rapnet_3 = 'https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/home/r.png'
export const ngtc_1 = 'https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/home/NG.png'
export const ngtc_2 = 'https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/home/ngtc.png'
export const tips = 'https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/home/tips.png'
export const part2 = 'https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/ngtc_3.png'
export const part3 = 'https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/hs.png'
export const part4 = 'https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/zb.png'
export const part5 = 'https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/hah.png'
export const address_bg = 'https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/address_bg.png'
export const dingyuehao = 'https://wanbaorapdia.oss-cn-shenzhen.aliyuncs.com/fileserver/image/rapnetbuy/customer-service-code-4.png'
export const Frame = 'https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/home/Frame.png'
export const Frame1 = 'https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/home/Frame-1.png'
export const Frame2 = 'https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/home/Frame-2.png'
export const d = 'https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/home/d.png'
export const $ = 'https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/home/$.png'
export const h = 'https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/home/h.png'
export const tel = '13266166161'
export const processPath = '/process'
export const aboutPath = '/about'